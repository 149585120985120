// **  Initial State
const initialState = {
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CHATS_LIST":
      return {
        ...state,
       chatsList: action.data
      };

      case "GET_CHAT_MESSAGES": 
      return {
        ...state,
        [`messages_chat_${action.params.chatId}`]: action.data
      }
    default:
      return state;
  }
};

export default chatReducer;
