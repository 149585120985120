// **  Initial State
const initialState = {};
const reformatData = (obj) => Object.values(obj).flat();
const mergeTwoObj = (obj) => {
  const result = {
    allowed: [],
    denied: [],
  };
  Object.values(obj).forEach((el) => {
    result.allowed = result.allowed.concat(el.allowed);
    result.denied = result.denied.concat(el.denied);
  });
  return result;
};

const itemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ITEMS_BALANCE":
      return {
        ...state,
        itemsBalance: action.data,
      };
    case "RUST_TABLE_GET":
      return { ...state, rustTable: action.payload };

    case "GET_MARKET_ITEMS":
      const data = mergeTwoObj(action.data);
      return {
        ...state,
        marketItems: data && data.allowed && data.allowed.map((el) => el),
        blockedMarket: data && data.denied && data.denied.map((el) => el),
      };
    case "GET_STEAM_BOT_ITEMS":
      return {
        ...state,
        steamBotItems: action.data,
      };
    case "GET_ALL_USERS_ITEMS":
      return { ...state, allUsersItems: action.data.data };

    case "UPDATE_ITEM":
      return { ...state, rustTable: action.callBackData };

    case "GET_INVENTORIES_TOTAL":
      return { ...state, inventsTotal: action.data };

    case "GET_ITEM_IDENTITIES":
      return {
        ...state,
        itemIdentities: action.payload.data,
        itemIdentitiesCount: action.payload.count,
      };
    case "GET_ITEM_IDENTITY_LOGS":
      return {
        ...state,
        itemIdentityLogs: {
          data: action.payload.data,
          totalCount: action.payload.total_count,
          page: action.payload.page,
        },
      };
    case "CLEAR_ITEM_IDENTITY_LOGS":
      return {
        ...state,
        itemIdentityLogs: {
          data: [],
          totalCount: 0,
          page: 0,
        },
      };

    default:
      return state;
  }
};

export default itemsReducer;
