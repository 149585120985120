// **  Initial State
const initialState = {};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ROLES":
      return {
        ...state,
        roles: action.data,
      };
    case "GET_PERMISSION":
      return {
        ...state,
        permission: action.data,
      };

    default:
      return state;
  }
};

export default rolesReducer;