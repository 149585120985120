// **  Initial State
const initialState = {};

const caseReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CASES":
            return {
                ...state,
               cases:action.payload
            };

        case "GET_CASES_GENERAL_INFO":
            return {
                ...state,
                tags:action.data.cases_tags,
                types: action.data.cases_types
            }

        default:
            return state;
    }
};

export default caseReducer;